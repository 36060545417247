export const ASLoginUrl =
  "https://www.alaskaair.com/www2/ssl/myalaskaair/MyAlaskaAir.aspx?CurrentForm=UCSignInStart&url=https://www.alaskaair.com";

  //----------------- Mileage Activity UI Component Constants -----------------
export const ActivityHeaders = ["Date", "Activity", "Status", "Miles", "Bonus Miles", "Total Miles"];
export const ActivitiesTableHeaders = [
  { header: "Date", accessor: (row: any) => row.Date },
  { header: "Activity", accessor: (row: any) => row.PartnerName },
  { header: "Status", accessor: (row: any) => (row.EligibilityReason ? "Declined" : row.Status) },
  { header: "Miles", accessor: (row: any) => Number(row.Miles).toLocaleString() },
  { header: "Bonus Miles", accessor: (row: any) => Number(row["Bonus Miles"]).toLocaleString() },
  { header: "Total Miles", accessor: (row: any) => Number(row["Total Miles"]).toLocaleString() },
];
