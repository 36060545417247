const cookies = Object.fromEntries(
  document.cookie.split('; ').map(x => x.split(/=(.+)/))
);

const easybizCookie = cookies['AS%5FACNT%5FCOMPANY'];
const travelagentCookie = cookies['AS%5FPROMO%5FLOGIN'];

const isEasyBiz =
  easybizCookie &&
  !!Object.fromEntries(easybizCookie.split('&').map(x => x.split('=')))
    .COMPANYNAME;
const isTravelAgent =
  travelagentCookie &&
  Object.fromEntries(travelagentCookie.split('&').map(x => x.split('=')))
    .STATUS === 'OK';

const whichHeaderFooter = () => {
  if (isEasyBiz) return 'easybiz';
  else if (isTravelAgent) return 'travelagent';
  return 'public';
};

export default whichHeaderFooter;
