import initialState, { AccountStateType } from "../state/InitialState";

type ActionType =
  | { type: "setTokenValue"; value: (typeof initialState)["Token"] }
  | { type: "setMileagePlanDetails"; value: (typeof initialState)["MileagePlanDetails"] }
  | { type: "setMileagePlanActivity"; value: (typeof initialState)["MileagePlanActivity"] }
  | { type: "setSuperUserFlag"; value: (typeof initialState)["isSuperUser"] }
  | { type: "clearAllFilters"; value: typeof initialState }
  | { type: "setError"; value: (typeof initialState)["Error"] };

const Reducer = (state: AccountStateType, action: ActionType): AccountStateType => {
  if (typeof state === "undefined") {
    return state;
  }

  switch (action.type) {
    case "setTokenValue":
      return {
        ...state,
        Token: {
          ...action.value,
        },
      };
    case "setMileagePlanDetails":
      return {
        ...state,
        MileagePlanDetails: {
          ...action.value,
        },
      };
    case "setMileagePlanActivity":
      return {
        ...state,
        MileagePlanActivity: [...action.value],
      };
    case "setSuperUserFlag":
      return {
        ...state,
        isSuperUser: action.value,
      };
    case "setError":
      return {
        ...state,
        Error: action.value,
      };
    default:
      return state;
  }
};

export default Reducer;
