const initialState = {
  Token: {
    Value: "",
    Guid: "",
    AlaskaLoyaltyNumber: "",
  },
  MileagePlanDetails: {
    MemberFullName: "",
    MileagePlanNumber: "",
    MemberBalance: "",
    TierName: "",
    FraudAlertFlag: "",
  },
  MileagePlanActivity: [
    {
      Date: "10/20/2007",
      Activity: "",
      Bonus: 0,
      "Bonus Miles": 10,
      Status: "",
      "Total Miles": 0,
    },
  ],
  isSuperUser: false,
  Error: { message: null },
};

export type AccountStateType = typeof initialState;

export default initialState;
