import "@alaskaairux/auro-loader";
import "./spinner.scss";

interface SpinnerProps {
  visible?: boolean;
  size?: "xsm"|"sm" | "md" | "lg";
}

const Spinner: React.FC<SpinnerProps> = ({ visible, size }) => {
  return (
    <>
    {visible && ( 
      <div className={`spinner spinner--${size}`}>
        <div className="spinner-container">{<auro-loader orbit onlight sm></auro-loader>}</div>
      </div>
    )}
    </>
  );
};

export default Spinner;
