import { appInsights } from "../services/AppInsights";
import initialState, { AccountStateType } from "../state/InitialState";
import { useEffect, useReducer } from "react";
import axios from "axios";
import Reducer from "../helpers/Reducer";
import jwtDecode from "jwt-decode";
import Spinner from "../components/ui/spinner/Spinner";
import { ASLoginUrl } from "../helpers/constants";

let counter = 0;

let MPDataService = () => {
  let [accountState, accountDataDispatch] = useReducer(Reducer, initialState);
  // Get account guid data from token
  let fetchDataFromToken = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_ALASKA_TOKEN_URL as string);
      counter++;
      console.log(`calling token ${counter} : ${JSON.stringify(data)}`);
      parseTokenAndRedirect(data, accountDataDispatch);
    } catch (ex: any) {
      appInsights?.trackException({ exception: ex });
      accountDataDispatch({
        type: "setError",
        value: new Error("Unable to retrieve your Account Information.") as any,
      });
    }
  };

  const redirectExpiredToken = (token: any) => {
    const decodedToken: any = decodeTokenAndFetchData(token);
    const nowPlus10Seconds: any = Date.now() / 1000; // +10 for network timeout

    // Redirect if token has expired.
    if (decodedToken.exp < nowPlus10Seconds) {
      redirect(ASLoginUrl + "&advise=eSessionTimeout");
    }
  };

  const parseTokenAndRedirect = (result: any, accountDataDispatch: any) => {
    const { loggedIn, token, accountGuid, alaskaLoyaltyNumber } = result;

    if (!loggedIn && process.env.REACT_APP_AUTHENTICATION === "true") {
      redirect(ASLoginUrl);
      return <Spinner />;
    }

    redirectExpiredToken(token);

    accountDataDispatch({
      type: "setTokenValue",
      value: { Value: token, Guid: accountGuid, AlaskaLoyaltyNumber: alaskaLoyaltyNumber },
    });
  };

  const decodeTokenAndFetchData = (token: string): any => {
    return jwtDecode(token);
  };

  const redirect = (path: string) => {
    window.location.href = path;
  };

  const getMPDetails = async () => {
    console.log("process url: " + process.env.REACT_APP_MPACTIVITY_BFF_MEMBERINFO_BASE_URL2);
    if (accountState.Token.AlaskaLoyaltyNumber && accountState.MileagePlanDetails.MileagePlanNumber === "") {
      try {
        let url: string = `${process.env.REACT_APP_MPACTIVITY_BFF_MEMBERINFO_BASE_URL2}`;
        const axiosInstance = axios.create({
          timeout: 30000,
        });
        const { data } = await axiosInstance.get(url, {
          headers: {
            Authorization: `Bearer ${accountState.Token.Value}`,
            AccountGuid: `${accountState.Token.Guid}`,
            "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_APIM_KEY}`,
          },
        });

        const MileagePlanData = data;

        const accountDataDetails: AccountStateType["MileagePlanDetails"] = {
          MemberFullName: MileagePlanData.MemberFullName,
          MileagePlanNumber: MileagePlanData.MileagePlanNumber,
          MemberBalance: MileagePlanData.MemberBalance.toLocaleString(),
          TierName: MileagePlanData.TierName,
          FraudAlertFlag: MileagePlanData.FraudAlertFlag,
        };
        accountDataDispatch({
          type: "setMileagePlanDetails",
          value: accountDataDetails,
        });
      } catch (ex: any) {
        appInsights?.trackException({ exception: ex });
        console.log(
          `An error occured querying Member Details for ${
            accountState.Token.AlaskaLoyaltyNumber ? accountState.Token.AlaskaLoyaltyNumber : `an unknown member`
          }`
        );
        accountDataDispatch({
          type: "setError",
          value: new Error("Unable to retrieve your Mileage plan Information.") as any,
        });
      }
    } else if (process.env.REACT_APP_RELEASE_ENV === "test" || process.env.REACT_APP_RELEASE_ENV === "qa") {
      console.log("Did you sign in to as.com?");
    }
  };

  useEffect(() => {
    fetchDataFromToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMPDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountState.Token]);

  return {
    accountState,
    accountDataDispatch,
    getMPDetails,
  };
};

export default MPDataService;
