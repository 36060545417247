import React from "react";
import initialState, { AccountStateType } from "../state/InitialState";

export type AccountContextType = {
  accountState: AccountStateType;
  accountDataDispatch: any;
  getMPDetails: any;
};

export default React.createContext<AccountContextType>({
  accountState: initialState,
  accountDataDispatch: () => {},
  getMPDetails: () => {},
});
